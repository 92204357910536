import * as React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { Element } from 'react-scroll'
import TestimonialsSwitcher from '../../components/Testimonials/TestimonialsSwitcher'
import Section from '../../components/Section/Section'
import Stroke from '../../components/Page/Stroke'
import Boundaries from '../../components/Boundaries/Boundaries'

interface TestimonialProps {
  data: any
}

class Testimonials extends React.Component<TestimonialProps> {
  render() {
    return (
      <StaticQuery
        query={query}
        render={data => (
          <Element name="testimonials">
            <Section>
              <Stroke title={'Why people love us'} subtitle={'AKA The Testimonials'}>
                <Boundaries>
                  <TestimonialsSwitcher
                    testimonials={data.datoCmsHomePage.testimonials}
                    longTimer={12000}
                    shortTimer={5000}
                  />
                </Boundaries>
              </Stroke>
            </Section>
          </Element>
        )}
      />
    )
  }
}

const query = graphql`
  query HomepageTestimonials {
    datoCmsHomePage {
      testimonials {
        bigLogo
        company
        description
        id
        image
        logo
        name
        thumb
        smallName
        smallerLogo
        title
      }
    }
  }
`

export default Testimonials
