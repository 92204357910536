import * as React from 'react'
import injectSheet from 'react-jss'
import TrustedCompanies from '../../components/SocialProof/TrustedCompanies'
import Upcell from '../../components/Upcell/Upcell'
import Testimonials from './testimonials'
import Pricing from './pricing'
import Splash from './splash'
import classNames from 'classnames'
// import {CloudinaryContext} from 'cloudinary-react'
const styles = {
  home: {},
}

interface HomeProps {
  classes?: any
}

class Home extends React.Component<HomeProps, {}> {
  render() {
    const { classes: c } = this.props

    return (
      <div className={classNames(c.home, 'home')}>
        {/* <CloudinaryContext secure cloudName="hq-app"> */}
        <Splash />
        <TrustedCompanies color={'grey'} hideOnDesktop />
        <Testimonials />
        <Upcell title={['Join HQ today', 'and start working centralized']} url={'https://hq.app/app'} />
        <Pricing />
        {/* </CloudinaryContext> */}
      </div>
    )
  }
}

export default injectSheet(styles)(Home)
